import React from "react"
import PropTypes from "prop-types"

import { useThemeUI } from "theme-ui"
import Box from "./box"
import Paper from "./paper"
import Heading from "./heading"
import Text from "./text"
import Columns from "./columns"
import Column from "./column"
import Divider from "./divider"
import Stack from "./stack"

const Headline = ({ as, size, children, sx }) => {
  return (
    <Box
      as={as || "h3"}
      sx={{
        fontFamily: "serif",
        fontWeight: "heading",
        lineHeight: 1.1,
        fontSize: size === "small" ? [3, 3, 4] : [5, 6, 8],
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

const Article = ({ title, children }) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <>
      {title && (
        <Box
          sx={{
            gridColumn: ["1 / -2", null, "1 / -2"],
          }}
        >
          <Headline>{title}</Headline>
        </Box>
      )}

      <Box
        as="article"
        lang="de"
        sx={{
          columnCount: [1, 2],
          hyphens: "auto",
          columnGap: theme.space[6],
          gridColumn: ["span 5", null, "span 4"],
        }}
      >
        {children}
      </Box>
    </>
  )
}

const Aside = ({ title, children }) => {
  return (
    <Stack as="aside" space={3}>
      {title && <Headline size="small">{title}</Headline>}

      <Box
        as="p"
        lang="de"
        sx={{
          hyphens: "auto",
        }}
      >
        {children}
      </Box>
    </Stack>
  )
}

const LocationText = ({ children }) => {
  return (
    <Box
      as="span"
      sx={{
        textTransform: "uppercase",
        fontSize: "0.8em",
        letterSpacing: "2px",
      }}
    >
      {children}.
    </Box>
  )
}

const Newspaper = ({ children }) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Paper p={[6, 8]}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr) 30%",
          gridTemplateRows: "repeat(5, auto)",
          gridGap: theme.space[6],
        }}
      >
        <Box sx={{ gridColumn: "1 / -1" }}>
          <Heading
            level={1}
            align="center"
            sx={{
              fontFamily: "serif",
              lineHeight: 1,
              textTransform: "uppercase",
              wordBreak: "break-word",
            }}
          >
            Schwäbischer Merkur
          </Heading>
        </Box>

        <Box
          sx={{
            gridColumn: "1 / -1",
            borderTopWidth: 4,
            borderBottomWidth: 4,
            borderStyle: "solid",
            borderColor: "contrast",
            pb: 2,
          }}
        >
          <Box
            sx={{
              borderBottomWidth: 1,
              borderStyle: "solid",
              borderColor: "contrast",
              py: 3,
            }}
          >
            <Columns space={0} alignX="space-between" alignY="center">
              <Column>
                <Text size={2}>Seit 1744</Text>
              </Column>
              <Column>
                <Text size={2} align="center">
                  03. November 1801
                </Text>
              </Column>
              <Column>
                <Text size={2} align="right">
                  45 cr.
                </Text>
              </Column>
            </Columns>
          </Box>
        </Box>

        {/** Mundtot */}
        <Article title="Mundtodt! Erklärung und Schuldensache">
          <LocationText>Stuttgart</LocationText> Der hießige Fuhrmann Christoph
          Merkel, Wittwer, ist wegen seines leichtsinnigen und
          verschwenderischen Lebens für mundtodt erklärt, und ihm Herr
          Gerichtsverwandter Becker als Güterpfleger bestellt worden. Es wird
          daher solches hiermit bekannt gemacht, und dabei angefügt, daß alle
          diejenigen, welche ihm ferner borgen oder sich in irgend etwas
          einlassen, keine Befriedigung zu erwarten haben. Zugleich werden seine
          Gläubiger aufgefordert, binnen 4 Wochen, in der hiesigen
          Stadtschreiberei ihre Forderungen einzugeben und zu beweisen. Den 9.
          Nov. 1801 - Stadtdirektion und Stadtgericht.
        </Article>

        {/** Work & Travel */}
        <Article title="Hauslehrer im Süden Frankreichs">{children}</Article>

        {/** Haus zu verkaufen */}
        <Article title="Haus zu verkaufen">
          <LocationText>Stuttgart</LocationText> Nr. 81 in der Poststraße,
          zunächst an der Königsstraße, ein zweistöckiges Wohnhaus im besten
          Zustande, enthaltend einen geräumigen gewölbten Keller mit einigen
          Lagerfässern, parterre einen Laden mit Komptoirzimmer, Magazin, kleine
          Küche und Hofstall, in jeder der beiden oberen Etagen 5 Zimmer und 1
          Küche, in den Mansarden 1 Zimmer und Balkon, nebst 4 Kammern, und auf
          der Bühne noch 3 Kammern, nebst einem kleinen Garten mit Holzstall
          hinter dem Haus; in den Hause selbst in der oberen Etage ist mit den
          Eigentümerinen ein Kauf abzuschliessen; wobei noch bemerkt wird, daß
          der größere Anteil des Haus Schillings gegen Verzinsung stehen bleiben
          kann.
        </Article>

        {/** Sidebar */}
        <Box
          sx={{
            gridColumn: ["1 / -1", null, "5 / 6"],
            gridRow: [null, null, "3 / 9"],
            borderTopWidth: [1, 1, 0],
            borderLeftWidth: [0, 0, 1],
            borderColor: "contrast",
            pt: [6, null, 0],
            pl: [0, null, 6],
            ml: [0, null, 6],
          }}
        >
          <Stack space={6}>
            <Aside title="Unterricht in der Mathematik.">
              <LocationText>Tübingen</LocationText> Jemand wünscht, um seine
              müßige Stunden auszufüllen, Unterricht in den mathematischen
              Wissenschaften, als Arithmetik, Algebra, Geometrie, Stereometrie,
              Trigonometrie etc. zu ertheilen. Das Nähere ist in Lit. U. Nr. 36.
              1 noch zu erfragen.
            </Aside>

            <Divider color="contrast" />

            <Aside title="Warnung vor einem Verschwender.">
              <LocationText>Berneck</LocationText> Der Unterzeichnete sieht sich
              veranlaßt, jedermann vor seinem Sohn Johann Mathias Buhl, vulgo
              Johann Buhl, gewesener Herren Bedienter zu warnen, daß ihm Niemand
              nichts auf Borg gibt, indem keine Zahlung für ihn geleistet werden
              kann.- Mathias Bühl
            </Aside>

            <Divider color="contrast" />

            <Aside title="Dienstgesucht">
              <LocationText>Maulbronn</LocationText> Ein Frauenzimmer von guter
              Herkunft, welche in allen Haushaltsbeschäftigungen erfahren ist,
              das Kleidermachen gelernt hat, und gut mit Kindern umzugehen weiß,
              wünscht bis Lichtmeß hier oder auf dem Lande einen Plaz zu
              bekommen. Lit. U. Nr. 344.
            </Aside>
          </Stack>
        </Box>
      </Box>
    </Paper>
  )
}

Newspaper.propTypes = {
  children: PropTypes.node,
}

export default Newspaper
