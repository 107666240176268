import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import MarkerHighlight from "../../../../../components/marker-highlight"
import Newspaper from "../../../../../components/newspaper"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const { getAnswer } = useCourseStore()

  const answer = getAnswer({
    courseId: "reiseerinnerung",
    chapterId: "01-suedfrankreich",
    taskId: "work-and-travel",
  })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/zusammenfassung" />
      }
    >
      <Seo title="Eine Work & Travel-Anzeige schreiben" />
      <Stack>
        <Heading as="h2" level={2}>
          Deine Work & Travel-Anzeige
        </Heading>

        <Newspaper>
          <p>{answer?.attention}</p>
          <br />
          <p>
            <MarkerHighlight>{answer?.interest}</MarkerHighlight>
          </p>
          <br />
          <p>{answer?.desire}</p>
          <br />
          <p>{answer?.action}</p>
        </Newspaper>
        {/* <Paragraph>
          <TextLink
            onClick={(event) => {
              event.preventDefault()
              window.print()
            }}
          >
            Anzeige drucken
          </TextLink>
        </Paragraph> */}
      </Stack>
    </LayoutCourse>
  )
}

export default Page
